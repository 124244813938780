import { createContext, useContext, useState } from 'react';

const GeneralContext = createContext();

export const GeneralProvider = ({ children }) => {
  return (
    <GeneralContext.Provider value={{}}>{children}</GeneralContext.Provider>
  );
};

export const useGeneral = () => useContext(GeneralContext);
