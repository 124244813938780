import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { FC, useEffect, useRef } from 'react';
import './ConversationSection.scss';

interface ConversationSectionProps {
  items: ItemType[];
  isWaitingForResponse: boolean;
  formatText: (text: string) => string;
}

export const ConversationSection: FC<ConversationSectionProps> = ({
  items,
  isWaitingForResponse,
  formatText,
}) => {
  const conversationRef = useRef<HTMLDivElement>(null);

  // Ajout de l'auto-scroll
  useEffect(() => {
    if (conversationRef.current) {
      conversationRef.current.scrollTop = conversationRef.current.scrollHeight;
    }
  }, [items, isWaitingForResponse]);

  return (
    <div
      data-component="ConversationSection"
      className="content-block conversation"
    >
      <div className="content-block-title">CONVERSATION</div>
      <div
        ref={conversationRef}
        data-conversation-content
        className="content-block-body"
      >
        {!items.length && `En attente de connexion...`}
        {items.map((conversationItem) => (
          <div className="conversation-item" key={conversationItem.id}>
            <span className={`speaker ${conversationItem.role || ''}`}>
              {(conversationItem.role || conversationItem.type).replaceAll(
                '_',
                ' '
              )}
              :
            </span>
            <span className="speaker-content">
              {conversationItem.type === 'function_call_output' && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatText(conversationItem.formatted.output || ''),
                  }}
                />
              )}
              {conversationItem.formatted.tool && (
                <span>
                  {conversationItem.formatted.tool.name}(
                  {JSON.stringify(conversationItem.formatted.tool.arguments)})
                </span>
              )}
              {conversationItem.role === 'user' && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatText(
                      conversationItem.formatted.transcript ||
                        (conversationItem.formatted.audio?.length
                          ? '(awaiting transcript)'
                          : conversationItem.formatted.text || '(item sent)')
                    ),
                  }}
                />
              )}
              {conversationItem.role === 'assistant' && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatText(
                      conversationItem.formatted.transcript ||
                        conversationItem.formatted.text ||
                        '(truncated)'
                    ),
                  }}
                />
              )}
            </span>
          </div>
        ))}
        {isWaitingForResponse && (
          <div className="waiting-for-response">En attente de réponse...</div>
        )}
      </div>
    </div>
  );
};
