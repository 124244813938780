import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import './LoginPage.scss';

export const LoginPage: React.FC = () => {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login, isAuthenticated } = useAuth();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const success = login(password);
        if (!success) {
            setError('Mot de passe incorrect');
            setPassword('');
        }
    };

    if (isAuthenticated) {
        return null;
    }

    return (
        <div className="login-page">
            <div className="login-container">
                <h2 className="login-title">
                    Connexion requise
                </h2>
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="input-group">
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Entrez le mot de passe"
                            required
                        />
                    </div>
                    {error && (
                        <p className="error-message">{error}</p>
                    )}
                    <button
                        type="submit"
                        className="login-button"
                    >
                        Se connecter
                    </button>
                </form>
            </div>
        </div>
    );
};