// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[data-component=App] {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
@media (max-width: 992px) {
  [data-component=App] {
    height: auto;
    min-height: 100%;
  }
}
@media (max-width: 576px) {
  [data-component=App] {
    font-size: 14px;
  }
}
@media (pointer: coarse) {
  [data-component=App] {
    -webkit-overflow-scrolling: touch;
  }
}
@supports (-webkit-touch-callout: none) {
  [data-component=App] {
    height: -webkit-fill-available;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EARF;IASI,YAAA;IACA,gBAAA;EAEF;AACF;AAAE;EAbF;IAcI,eAAA;EAGF;AACF;AADE;EAjBF;IAkBI,iCAAA;EAIF;AACF;AAFE;EArBF;IAsBI,8BAAA;EAKF;AACF","sourcesContent":["[data-component='App'] {\n  height: 100%;\n  width: 100%;\n  position: relative;\n  overflow-x: hidden;\n  display: flex;\n  flex-direction: column;\n\n  @media (max-width: 992px) {\n    height: auto;\n    min-height: 100%;\n  }\n\n  @media (max-width: 576px) {\n    font-size: 14px;\n  }\n\n  @media (pointer: coarse) {\n    -webkit-overflow-scrolling: touch;\n  }\n\n  @supports (-webkit-touch-callout: none) {\n    height: -webkit-fill-available;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
