import { FC, useEffect, useRef } from 'react';

interface BackgroundAudioProps {
  src: string;
  enabled: boolean;
}

export const BackgroundAudio: FC<BackgroundAudioProps> = ({ src, enabled }) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = 0.1;
      audioRef.current.loop = true;

      if (enabled) {
        audioRef.current.play().catch(error => {
          console.log("Lecture automatique non autorisée:", error);
        });
      } else {
        audioRef.current.pause();
      }
    }
  }, [enabled]);

  return (
    <div className="background-audio">
      <audio ref={audioRef} src={src} />
    </div>
  );
};
