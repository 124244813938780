import { FC } from 'react';
import { prompts } from '../../utils/prompts';
import { Button } from '../button/Button';
import './PromptSelector.scss';

interface PromptSelectorProps {
  onPromptSelect: (content: string) => void;
  currentInstructions: string;
  isConnected: boolean;
  onInstructionsChange: (instructions: string) => void;
}

export const PromptSelector: FC<PromptSelectorProps> = ({
  onPromptSelect,
  currentInstructions,
  isConnected,
  onInstructionsChange,
}) => {
  const handlePromptSelection = (promptContent: string) => {
    console.log('Selecting prompt with content:', promptContent);

    if (!promptContent) {
      console.error('Empty prompt content selected');
      return;
    }

    onPromptSelect(promptContent);
    onInstructionsChange(promptContent);
  }

  return (
    <div className="prompt-selector">
      <div className="prompt-selector-title">Scripts prédéfinis</div>
      <div className="prompt-buttons">
        {prompts.map((prompt) => (
          <Button
            key={prompt.id}
            label={prompt.name}
            icon={prompt.icon}
            onClick={() => handlePromptSelection(prompt.content)}
            buttonStyle={currentInstructions === prompt.content ? 'action' : 'regular'}
            disabled={isConnected}
          />
        ))}
      </div>
    </div>
  );
};
