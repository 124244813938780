import { FC, RefObject } from 'react';
import { ArrowUp, ArrowDown } from 'react-feather';
import { RealtimeEvent } from '../../types';

interface EventLogSectionProps {
  realtimeEvents: RealtimeEvent[];
  formatTime: (timestamp: string) => string;
  usage: { cost: number };
  eventsScrollRef: RefObject<HTMLDivElement>;
}

export const EventLogSection: FC<EventLogSectionProps> = ({
  realtimeEvents,
  formatTime,
  usage,
  eventsScrollRef,
}) => {
  return (
    <div className="content-block events">
      <div className="content-block-title">
        LOGS
        <span className="cost">${usage.cost.toFixed(2)}</span>
      </div>
      <div className="content-block-body" ref={eventsScrollRef}>
        {!realtimeEvents.length && `En attente de connexion...`}
        {realtimeEvents.map((realtimeEvent) => (
          <div className="event" key={realtimeEvent.event.event_id}>
            <span className="event-timestamp">
              {formatTime(realtimeEvent.time)}
            </span>
            <span className={`event-source ${realtimeEvent.source}`}>
              {realtimeEvent.source === 'client' ? <ArrowUp /> : <ArrowDown />}
              {realtimeEvent.source}
            </span>
            <span className="event-type">
              {realtimeEvent.event.type}
              {realtimeEvent.count && ` (${realtimeEvent.count})`}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
