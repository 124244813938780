import { FC } from 'react';
import { Edit } from 'react-feather';
import { PromptSelector } from '../PromptSelector/PromptSelector';
import { UserPromptManager } from '../UserPromptManager/UserPromptManager';
import './InstructionsSection.scss';

interface InstructionsSectionProps {
  isConnected: boolean;
  isEditingInstructions: boolean;
  currentInstructions: string;
  tempInstructions: string;
  onUserPromptChange: (prompt: string) => void;
  onPromptSelect: (content: string) => void;
  onInstructionsChange: (instructions: string) => void;
  onEditStart: () => void;
  onEditCancel: () => void;
  onEditApply: () => void;
  onTempInstructionsChange: (instructions: string) => void;
}

export const InstructionsSection: FC<InstructionsSectionProps> = ({
  isConnected,
  isEditingInstructions,
  currentInstructions,
  tempInstructions,
  onUserPromptChange,
  onPromptSelect,
  onInstructionsChange,
  onEditStart,
  onEditCancel,
  onEditApply,
  onTempInstructionsChange,
}) => {
  return (
    <div className="content-block instructions">
      <div className="content-block-title">
        INSTRUCTIONS
        {!isEditingInstructions && (
          <button
            className="edit-icon-button"
            onClick={onEditStart}
            disabled={isConnected}
          >
            <Edit />
          </button>
        )}
      </div>
      <div className="content-block-body">
        <UserPromptManager
          isConnected={isConnected}
          onPromptChange={onUserPromptChange}
        />
        <PromptSelector
          onPromptSelect={onPromptSelect}
          currentInstructions={currentInstructions}
          isConnected={isConnected}
          onInstructionsChange={onInstructionsChange}
        />
        {isEditingInstructions && (
          <div className="editing-container">
            <textarea
              value={tempInstructions}
              onChange={(e) => onTempInstructionsChange(e.target.value)}
              placeholder="Entrez vos instructions..."
            />
            <div className="button-group">
              <button className="button-action" onClick={onEditApply}>
                Appliquer
              </button>
              <button className="button-regular" onClick={onEditCancel}>
                Annuler
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
