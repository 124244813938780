// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-block.voice-activity {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}
.content-block.voice-activity .content-block-body {
  flex: 1 1;
  min-height: 0;
  padding: 1rem;
  overflow: hidden;
}
.content-block.voice-activity .content-block-body .visualization-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: calc(100% - 1rem);
  gap: 1rem;
}
.content-block.voice-activity .content-block-body .visualization-content .visualization-entry {
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.content-block.voice-activity .content-block-body .visualization-content .visualization-entry span {
  display: block;
  text-align: center;
  color: #fe7129;
  font-weight: 500;
  padding-bottom: 0.75rem;
  flex-shrink: 0;
}
.content-block.voice-activity .content-block-body .visualization-content .visualization-entry canvas {
  flex: 1 1;
  width: 100%;
  min-height: 0;
  border-radius: 4px;
}
.content-block.voice-activity .content-block-body .visualization-content .visualization-entry.client canvas {
  background: rgba(0, 153, 255, 0.05);
}
.content-block.voice-activity .content-block-body .visualization-content .visualization-entry.server canvas {
  background: rgba(0, 153, 0, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/components/VoiceActivitySection/VoiceActivitySection.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;AACF;AACE;EACE,SAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;AACJ;AACI;EACE,aAAA;EACA,qCAAA;EACA,yBAAA;EACA,SAAA;AACN;AACM;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AACR;AACQ;EACE,cAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,cAAA;AACV;AAEQ;EACE,SAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAAV;AAGQ;EACE,mCAAA;AADV;AAIQ;EACE,iCAAA;AAFV","sourcesContent":[".content-block.voice-activity {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  background: white;\n  border-radius: 8px;\n  overflow: hidden;\n\n  .content-block-body {\n    flex: 1;\n    min-height: 0;\n    padding: 1rem;\n    overflow: hidden; // Empêche le scroll\n\n    .visualization-content {\n      display: grid;\n      grid-template-columns: repeat(2, 1fr);\n      height: calc(100% - 1rem); // Pour éviter le scroll\n      gap: 1rem;\n\n      .visualization-entry {\n        display: flex;\n        flex-direction: column;\n        min-height: 0; // Important pour éviter le scroll dans flex\n\n        span {\n          display: block;\n          text-align: center;\n          color: #fe7129;\n          font-weight: 500;\n          padding-bottom: 0.75rem;\n          flex-shrink: 0; // Empêche le texte de rétrécir\n        }\n\n        canvas {\n          flex: 1;\n          width: 100%;\n          min-height: 0; // Important pour flex\n          border-radius: 4px;\n        }\n\n        &.client canvas {\n          background: rgba(0, 153, 255, 0.05);\n        }\n\n        &.server canvas {\n          background: rgba(0, 153, 0, 0.05);\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
