import { FC, useEffect, useState } from 'react';
import './UserPromptManager.scss';
import { useDebounce } from '../../hooks/useDebounce';
import { AlertCircle, Mail, Phone, User, Icon } from 'react-feather';

interface UserPromptManagerProps {
  isConnected: boolean;
  onPromptChange: (prompt: string) => void;
}

interface UserInfo {
  name: string;
  phone: string;
  email: string;
}

export const UserPromptManager: FC<UserPromptManagerProps> = ({
  isConnected,
  onPromptChange,
}) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(() => {
    const saved = localStorage.getItem('userPromptInfo');
    return saved ? JSON.parse(saved) : { name: '', phone: '', email: '' };
  });
  const [validations, setValidations] = useState({
    name: true,
    phone: true,
    email: true,
  });

  const debouncedUserInfo = useDebounce(userInfo, 500);

  const validatePhoneNumber = (phone: string): boolean => {
    const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    return phone.length === 0 || phoneRegex.test(phone);
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return email.length === 0 || emailRegex.test(email);
  };

  const formatPhoneNumber = (phone: string): string => {
    let cleaned = phone.replace(/\D/g, '');
    if (cleaned.startsWith('33')) cleaned = '0' + cleaned.substring(2);
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (match) {
      return match.slice(1).join(' ');
    }
    return cleaned;
  };

  const handleInputChange = (field: keyof UserInfo, value: string) => {
    let processedValue = value;
    if (field === 'phone') {
      processedValue = formatPhoneNumber(value);
    }

    setUserInfo((prev) => ({ ...prev, [field]: processedValue }));

    let isValid = true;
    if (field === 'phone') isValid = validatePhoneNumber(processedValue);
    if (field === 'email') isValid = validateEmail(processedValue);

    setValidations((prev) => ({
      ...prev,
      [field]: isValid,
    }));
  };

  useEffect(() => {
    const prompt = `Bonjour, je suis ${userInfo.name || '[NOM]'}. 
Mon numéro de téléphone est le ${userInfo.phone || '[TÉLÉPHONE]'} 
et mon adresse email est ${userInfo.email || '[EMAIL]'}.`;

    onPromptChange(prompt);
    localStorage.setItem('userPromptInfo', JSON.stringify(userInfo));
  }, [debouncedUserInfo]);

  const renderInput = (
    field: keyof UserInfo,
    label: string,
    type: string,
    placeholder: string,
    Icon: Icon
  ) => {
    const isValid = validations[field];
    const value = userInfo[field];

    return (
      <div className="input-group">
        <label htmlFor={field}>
          <Icon size={16} />
          {label}
          <span className="required">*</span>
        </label>
        <div className="input-wrapper">
          <input
            id={field}
            type={field}
            value={value}
            onChange={(e) => handleInputChange(field, e.target.value)}
            placeholder={placeholder}
            disabled={isConnected}
            className={!isValid ? 'error' : ''}
            required
          />
          {!isValid && (
            <div className="error-indicator">
              <AlertCircle size={16} />
            </div>
          )}
        </div>
        {!isValid && <span className="error-message">Format invalide</span>}
      </div>
    );
  };

  return (
    <div className="user-prompt-manager">
      <div className="prompt-fields">
        {renderInput('name', 'Nom', 'text', 'Votre nom', User)}
        {renderInput('phone', 'Téléphone', 'tel', '06 XX XX XX XX', Phone)}
        {renderInput('email', 'Email', 'email', 'votre@email.com', Mail)}
      </div>
    </div>
  );
};
