// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.prompt-selector {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1rem;
}
.prompt-selector .prompt-selector-title {
  font-family: "Roboto Mono", monospace;
  font-size: 0.9rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #dfe4ea;
}
.prompt-selector .prompt-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}
@media (max-width: 768px) {
  .prompt-selector .prompt-buttons {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/PromptSelector/PromptSelector.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AACF;AACE;EACE,qCAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,sBAAA;EACA,gCAAA;AACJ;AAEE;EACE,aAAA;EACA,2DAAA;EACA,SAAA;AAAJ;AAEI;EALF;IAMI,0BAAA;EACJ;AACF","sourcesContent":[".prompt-selector {\n  background-color: #f8f9fa;\n  border-radius: 12px;\n  padding: 1.5rem;\n  margin-bottom: 1rem;\n\n  .prompt-selector-title {\n    font-family: 'Roboto Mono', monospace;\n    font-size: 0.9rem;\n    font-weight: 600;\n    color: #2c3e50;\n    margin-bottom: 1rem;\n    padding-bottom: 0.5rem;\n    border-bottom: 2px solid #dfe4ea;\n  }\n\n  .prompt-buttons {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));\n    gap: 1rem;\n\n    @media (max-width: 768px) {\n      grid-template-columns: 1fr;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
