/**
 * Running a local relay server will allow you to hide your API key
 * and run custom logic on the server
 *
 * Set the local relay server address to:
 * REACT_APP_LOCAL_RELAY_SERVER_URL=http://localhost:8081
 *
 * This will also require you to set OPENAI_API_KEY= in a `.env` file
 * You can run it with `npm run relay`, in parallel with `npm start`
 */
const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ConversationSection } from '../components/ConversationSection/ConversationSection';
import { EventLogSection } from '../components/EventLogSection/EventLogSection';
import { Header } from '../components/Header/Header';
import { InstructionsSection } from '../components/InstructionsSection/InstructionsSection';
import { VoiceActivitySection } from '../components/VoiceActivitySection/VoiceActivitySection';
import { useAuth } from '../contexts/AuthContext';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions as defaultInstructions } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';
import './ConsolePage.scss';

interface UsageTotal {
  input_audio_tokens: number;
  output_audio_tokens: number;
  input_text_tokens: number;
  output_text_tokens: number;
  cost: number;
}

interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

const formatText = (text: string) => {
  if (!text) return '';
  return text
    .replace(/\\n/g, '\n')
    .replace(/\n\n/g, '<br/><br/>')
    .replace(/\n/g, '<br/>')
    .replace(/\s-\s/g, '<br/>- ')
    .trim();
};

const log = (component: string, action: string, details?: any) => {
  console.log(
    JSON.stringify(
      {
        timestamp: new Date().toISOString(),
        component,
        action,
        details: details || {},
      },
      null,
      2
    )
  );
};

const defaultUsage: UsageTotal = {
  input_audio_tokens: 0,
  output_audio_tokens: 0,
  input_text_tokens: 0,
  output_text_tokens: 0,
  cost: 0,
};

export function ConsolePage() {
  // Auth & API
  const { logout } = useAuth();
  const apiKey = LOCAL_RELAY_SERVER_URL
    ? ''
    : localStorage.getItem('tmp::voice_api_key') ||
      prompt('OpenAI API Key') ||
      '';

  if (apiKey) {
    localStorage.setItem('tmp::voice_api_key', apiKey);
  }

  // Session management
  const sessionId = useRef(
    localStorage.getItem('sessionId') || generateSessionId()
  );
  useEffect(() => {
    localStorage.setItem('sessionId', sessionId.current);
  }, []);

  // Core references
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      LOCAL_RELAY_SERVER_URL
        ? { url: LOCAL_RELAY_SERVER_URL }
        : { apiKey, dangerouslyAllowAPIKeyInBrowser: true }
    )
  );

  // UI references
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const startTimeRef = useRef<string>(new Date().toISOString());

  // State management
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [usage, setUsage] = useState<UsageTotal>(defaultUsage);
  const [currentVoice, setCurrentVoice] = useState<string>('realtime-sage');
  const [currentVoiceRealtime, setCurrentVoiceRealtime] = useState<
    'alloy' | 'shimmer' | 'echo' | 'ash' | 'ballad' | 'coral' | 'sage' | 'verse'
  >('sage');
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [trackId, setTrackId] = useState(1);
  const [currentInstructions, setCurrentInstructions] =
    useState(defaultInstructions);
  const [isEditingInstructions, setIsEditingInstructions] = useState(false);
  const [tempInstructions, setTempInstructions] = useState(defaultInstructions);
  const [userPrompt, setUserPrompt] = useState('');
  const [timeRemaining, setTimeRemaining] = useState<number>(300);

  // Timer references
  const disconnectTimerRef = useRef<NodeJS.Timeout | null>(null);
  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  // Utility functions
  const formatTimeRemaining = useCallback((seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
      .toString()
      .padStart(2, '0')}`;
  }, []);

  function generateSessionId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  const applyInstructions = useCallback(() => {
    setCurrentInstructions(tempInstructions);
    clientRef.current.updateSession({ instructions: tempInstructions });
    setIsEditingInstructions(false);
  }, [tempInstructions]);

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * When you click the API key
   */
  const resetAPIKey = useCallback(() => {
    const newApiKey = prompt('OpenAI API Key');
    if (newApiKey !== null) {
      localStorage.clear();
      localStorage.setItem('tmp::voice_api_key', newApiKey);
      window.location.reload();
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    // Clear timers
    [disconnectTimerRef, countdownIntervalRef].forEach((ref) => {
      if (ref.current) {
        clearTimeout(ref.current);
        ref.current = null;
      }
    });

    // Reset states
    setTimeRemaining(300);
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setUsage(defaultUsage);

    // Clean up connections
    const [client, wavRecorder, wavStreamPlayer] = [
      clientRef.current,
      wavRecorderRef.current,
      wavStreamPlayerRef.current,
    ];

    client.disconnect();
    await wavRecorder.end();
    setTrackId((prev) => prev + 1);
    wavStreamPlayer.interrupt();
  }, []);

  useEffect(() => {
    return () => {
      if (disconnectTimerRef.current) {
        clearTimeout(disconnectTimerRef.current);
      }
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder taks speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    log('ConsolePage', 'connectConversation.start', {
      currentInstructions,
      currentVoice,
      sessionId: sessionId.current,
    });

    // Reset timers
    setTimeRemaining(300);
    [disconnectTimerRef, countdownIntervalRef].forEach((ref) => {
      if (ref.current) {
        clearTimeout(ref.current);
        ref.current = null;
      }
    });

    const [client, wavRecorder, wavStreamPlayer] = [
      clientRef.current,
      wavRecorderRef.current,
      wavStreamPlayerRef.current,
    ];

    // Reset state
    startTimeRef.current = new Date().toISOString();
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);

    try {
      await wavRecorder.begin();
      await wavStreamPlayer.connect();
      await client.connect();
      setIsConnected(true);

      // Ensure connection is established
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (client.isConnected()) {
        // Setup timers
        disconnectTimerRef.current = setTimeout(() => {
          log('ConsolePage', 'auto.disconnect.timeout');
          disconnectConversation();
        }, 300000);

        countdownIntervalRef.current = setInterval(() => {
          setTimeRemaining((prev) => {
            if (prev <= 1) {
              if (countdownIntervalRef.current) {
                clearInterval(countdownIntervalRef.current);
              }
              return 0;
            }
            return prev - 1;
          });
        }, 1000);

        // Configure session
        client.updateSession({
          instructions: currentInstructions,
          voice: currentVoiceRealtime as any,
          turn_detection: {
            type: 'server_vad',
            threshold: 0.85,
          },
        });

        // Send initial prompt if exists
        if (userPrompt?.trim()) {
          client.sendUserMessageContent([
            {
              type: 'input_text',
              text: userPrompt,
            },
          ]);
        }

        // Start recording if using VAD
        if (client.getTurnDetectionType() === 'server_vad') {
          await wavRecorder.record((data) => {
            if (client.isConnected()) {
              client.appendInputAudio(data.mono);
            }
          });
        }
      }
    } catch (error: any) {
      log('ConsolePage', 'connection.error', { error: error.message });
      setIsConnected(false);
    }
  }, [
    currentVoice,
    currentInstructions,
    userPrompt,
    currentVoiceRealtime,
    disconnectConversation,
  ]);

  const handlePromptSelect = useCallback((content: string) => {
    log('ConsolePage', 'prompt.selected', {
      contentLength: content?.length,
      contentPreview: content?.substring(0, 100),
    });

    setTempInstructions(content);
    setCurrentInstructions(content);

    if (clientRef.current?.isConnected()) {
      clientRef.current.updateSession({ instructions: content });
    }
  }, []);

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = useCallback(async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;

    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }

    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });

    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = document.querySelectorAll(
      '[data-conversation-content]'
    );
    conversationEls.forEach((el) => {
      (el as HTMLDivElement).scrollTop = el.scrollHeight;
    });
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;
    let animationFrame: number;

    const render = () => {
      if (!isLoaded) return;

      const renderCanvas = (
        canvas: HTMLCanvasElement | null,
        source: any,
        getValues: () => Float32Array,
        color: string
      ) => {
        if (!canvas) return;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        if (!canvas.width || !canvas.height) {
          canvas.width = canvas.offsetWidth;
          canvas.height = canvas.offsetHeight;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        WavRenderer.drawBars(canvas, ctx, getValues(), color, 10, 0, 8);
      };

      // Client canvas
      renderCanvas(
        clientCanvasRef.current,
        wavRecorderRef.current,
        () =>
          wavRecorderRef.current.recording
            ? wavRecorderRef.current.getFrequencies('voice').values
            : new Float32Array([0]),
        '#0099ff'
      );

      // Server canvas
      renderCanvas(
        serverCanvasRef.current,
        wavStreamPlayerRef.current,
        () =>
          wavStreamPlayerRef.current.analyser
            ? wavStreamPlayerRef.current.getFrequencies('voice').values
            : new Float32Array([0]),
        '#009900'
      );

      animationFrame = window.requestAnimationFrame(render);
    };

    render();

    return () => {
      isLoaded = false;
      if (animationFrame) {
        cancelAnimationFrame(animationFrame);
      }
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    const client = clientRef.current;
    const eventEmitter = client.realtime;

    const handleRealtimeEvent = (realtimeEvent: RealtimeEvent) => {
      if (!realtimeEvent?.event?.type) return;

      setRealtimeEvents((prevEvents) => {
        const lastEvent = prevEvents[prevEvents.length - 1];
        const eventType = realtimeEvent.event.type;

        if (
          eventType === 'conversation.updated' ||
          eventType === 'conversation.item.created'
        ) {
          setItems(client.conversation.getItems());
        }

        if (lastEvent?.event?.type === eventType) {
          return [
            ...prevEvents.slice(0, -1),
            { ...lastEvent, count: (lastEvent.count || 0) + 1 },
          ];
        }

        return [...prevEvents, realtimeEvent];
      });
    };

    const handleInterruption = async () => {
      try {
        setTrackId((prev) => prev + 1);
        const trackSampleOffset = wavStreamPlayerRef.current.interrupt();
        if (trackSampleOffset?.trackId) {
          client.cancelResponse(
            trackSampleOffset.trackId,
            trackSampleOffset.offset
          );
        }
      } catch (error) {
        console.error('Error handling interruption:', error);
      }
    };

    try {
      client.on('realtime.event', handleRealtimeEvent);
      eventEmitter.on('conversation.interrupted', handleInterruption);

      return () => {
        client.reset();
      };
    } catch (error) {
      console.error('Error setting up event listeners:', error);
    }
  }, []);

  useEffect(() => {
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    interface ConversationItem {
      id: string;
      status: string;
      formatted: {
        audio?: Int16Array;
        file?: any;
      };
    }

    interface ConversationUpdate {
      item: ConversationItem;
      delta?: {
        audio?: Int16Array;
      };
    }

    const handleMessage = async ({ item, delta }: ConversationUpdate) => {
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }

      const items = client.conversation.getItems();
      setItems(items);

      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }

      setIsWaitingForResponse(false);
    };

    client.on('conversation.updated', handleMessage);

    return () => {
      client.reset();
    };
  }, []);

  useEffect(() => {
    changeTurnEndType('server_vad');
  }, [changeTurnEndType]);

  const handleVoiceChange = useCallback(
    (newVoice: string) => {
      log('ConsolePage', 'voice.change.requested', { newVoice, currentVoice });
      const voiceId = newVoice.substring(9);
      setCurrentVoiceRealtime(voiceId as any);
      setCurrentVoice(newVoice);
      clientRef.current.updateSession({ voice: voiceId as any });
    },
    [currentVoice]
  );

  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <Header
        isConnected={isConnected}
        showApiKey={!LOCAL_RELAY_SERVER_URL}
        apiKey={apiKey}
        currentVoice={currentVoice}
        onVoiceChange={handleVoiceChange}
        onConnect={connectConversation}
        onDisconnect={disconnectConversation}
        onResetApiKey={resetAPIKey}
        onLogout={logout}
        timeRemaining={isConnected ? formatTimeRemaining(timeRemaining) : null}
      />

      <div className="content-main">
        <div className="content-split">
          <ConversationSection
            items={items}
            isWaitingForResponse={isWaitingForResponse}
            formatText={formatText}
          />

          <InstructionsSection
            isConnected={isConnected}
            isEditingInstructions={isEditingInstructions}
            currentInstructions={currentInstructions}
            tempInstructions={tempInstructions}
            onUserPromptChange={setUserPrompt}
            onPromptSelect={handlePromptSelect}
            onInstructionsChange={setCurrentInstructions}
            onEditStart={() => setIsEditingInstructions(true)}
            onEditCancel={() => {
              setIsEditingInstructions(false);
              setTempInstructions(currentInstructions);
            }}
            onEditApply={applyInstructions}
            onTempInstructionsChange={setTempInstructions}
          />
        </div>

        <div className="content-bottom">
          <VoiceActivitySection
            clientCanvasRef={clientCanvasRef}
            serverCanvasRef={serverCanvasRef}
          />

          <EventLogSection
            realtimeEvents={realtimeEvents}
            formatTime={formatTime}
            usage={usage}
            eventsScrollRef={eventsScrollRef}
          />
        </div>
      </div>

      <footer className="content-footer">
        <span>
          DataIPA | Applied Gen AI for Data Science, Analytics and Business
        </span>
      </footer>
    </div>
  );
}
