// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-block.conversation .content-block-body {
  height: 450px;
  overflow-y: auto;
}
.content-block.conversation .conversation-item {
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #dfe4ea;
}
.content-block.conversation .conversation-item.user {
  background-color: rgba(52, 152, 219, 0.05);
  margin-left: 2rem;
}
.content-block.conversation .conversation-item.assistant {
  background-color: rgba(254, 113, 41, 0.05);
  margin-right: 2rem;
}
.content-block.conversation .conversation-item .speaker {
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  display: block;
}
.content-block.conversation .conversation-item .speaker-content {
  color: #34495e;
  line-height: 1.6;
}
.content-block.conversation .waiting-for-response {
  text-align: center;
  color: #7f8c8d;
  padding: 1rem;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/ConversationSection/ConversationSection.scss"],"names":[],"mappings":"AA+BE;EACE,aAAA;EACA,gBAAA;AA9BJ;AAiCE;EACE,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AA/BJ;AAiCI;EACE,0CAAA;EACA,iBAAA;AA/BN;AAkCI;EACE,0CAAA;EACA,kBAAA;AAhCN;AAmCI;EACE,gBAAA;EACA,cA/Cc;EAgDd,qBAAA;EACA,cAAA;AAjCN;AAoCI;EACE,cAxDO;EAyDP,gBAAA;AAlCN;AAsCE;EACE,kBAAA;EACA,cA9De;EA+Df,aAAA;EACA,kBAAA;AApCJ","sourcesContent":["$primary-color: #fe7129;\n$secondary-color: #2ecc71;\n$accent-color: #3498db;\n$background-color: #f5f7fa;\n$text-color: #34495e;\n$light-text-color: #7f8c8d;\n$border-color: #dfe4ea;\n$header-text-color: #2c3e50;\n$error-color: #e74c3c;\n$white: #ffffff;\n\n// Mixins\n@mixin flex-center {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@mixin card-shadow {\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);\n}\n\n@mixin hover-transition {\n  transition: all 0.2s ease-in-out;\n  &:hover {\n    transform: translateY(-1px);\n    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);\n  }\n}\n\n.content-block.conversation {\n  .content-block-body {\n    height: 450px;\n    overflow-y: auto;\n  }\n\n  .conversation-item {\n    margin-bottom: 1rem;\n    padding: 1rem;\n    border-radius: 8px;\n    border: 1px solid $border-color;\n\n    &.user {\n      background-color: rgba($accent-color, 0.05);\n      margin-left: 2rem;\n    }\n\n    &.assistant {\n      background-color: rgba($primary-color, 0.05);\n      margin-right: 2rem;\n    }\n\n    .speaker {\n      font-weight: 600;\n      color: $header-text-color;\n      margin-bottom: 0.5rem;\n      display: block;\n    }\n\n    .speaker-content {\n      color: $text-color;\n      line-height: 1.6;\n    }\n  }\n\n  .waiting-for-response {\n    text-align: center;\n    color: $light-text-color;\n    padding: 1rem;\n    font-style: italic;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
