// src/App.tsx
import { ConsolePage } from './pages/ConsolePage';
import { GeneralProvider } from "./contexts/GeneralContext";
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './components/ProtectedRoute';
import { LoginPage } from './components/LoginPage';
import './App.scss';

function App() {
    return (
        <div data-component="App">
            <AuthProvider>
                <GeneralProvider>
                    <ProtectedRoute>
                        <ConsolePage />
                    </ProtectedRoute>
                    <LoginPage />
                </GeneralProvider>
            </AuthProvider>
        </div>
    );
}

export default App;