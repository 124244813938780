import { Edit, Icon } from 'react-feather';
import assuranceAuto from './assurance-auto';
import supportCRM from './support-crm-dynamics';
import formation from './formation';

export interface Prompt {
  id: string;
  name: string;
  content: string;
  icon?: Icon;
}

export const prompts: Prompt[] = [
  {
    id: 'assurance-auto',
    name: 'Assurance Auto',
    content: assuranceAuto,
    icon: Edit,
  },
  {
    id: 'support-crm-dynamics',
    name: 'Support CRM Dynamics',
    content: supportCRM,
    icon: Edit,
  },
  {
    id: 'formation',
    name: 'Formation permis',
    content: formation,
    icon: Edit,
  },
];
