import { FC, RefObject } from 'react';
import './VoiceActivitySection.scss';

interface VoiceActivitySectionProps {
  clientCanvasRef: RefObject<HTMLCanvasElement>;
  serverCanvasRef: RefObject<HTMLCanvasElement>;
}

export const VoiceActivitySection: FC<VoiceActivitySectionProps> = ({
  clientCanvasRef,
  serverCanvasRef,
}) => {
  return (
    <div className="content-block voice-activity">
      <div className="content-block-title">VOIX</div>
      <div className="content-block-body">
        <div className="visualization-content">
          <div className="visualization-entry client">
            <span>Utilisateur</span>
            <canvas ref={clientCanvasRef} />
          </div>
          <div className="visualization-entry server">
            <span>Assistant</span>
            <canvas ref={serverCanvasRef} />
          </div>
        </div>
      </div>
    </div>
  );
};
