import { FC } from 'react';
import './VoiceDropdown.scss';

interface VoiceOption {
  id: string;
  name: string;
  type: 'realtime' | 'elevenlabs';
}

interface VoiceDropdownProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

const voiceOptions: VoiceOption[] = [
  { id: 'realtime-alloy', name: 'Elodie', type: 'realtime' },
  { id: 'realtime-shimmer', name: 'Sandrine', type: 'realtime' },
  { id: 'realtime-echo', name: 'Hervé', type: 'realtime' },
  { id: 'realtime-ash', name: 'Etienne', type: 'realtime' },
  { id: 'realtime-ballad', name: 'Thomas', type: 'realtime' },
  { id: 'realtime-coral', name: 'Manuel', type: 'realtime' },
  { id: 'realtime-sage', name: 'Marie', type: 'realtime' },
  { id: 'realtime-verse', name: 'Pascal', type: 'realtime' },
];

export const VoiceDropdown: FC<VoiceDropdownProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <div className="voice-dropdown">
      <select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        className="voice-select"
      >
        {voiceOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};
