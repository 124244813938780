import { FC, useState } from 'react';
import {
  AlertCircle,
  Edit,
  Menu,
  Volume2,
  VolumeX,
  X,
  Zap,
} from 'react-feather';
import { BackgroundAudio } from '../BackgroundAudio/BackgroundAudio';
import { VoiceDropdown } from '../VoiceDropdown/VoiceDropdown';
import './Header.scss';

interface HeaderProps {
  isConnected: boolean;
  showApiKey: boolean;
  apiKey: string;
  currentVoice: string;
  timeRemaining: string | null;
  onVoiceChange: (voice: string) => void;
  onConnect: () => void;
  onDisconnect: () => void;
  onResetApiKey: () => void;
  onLogout: () => void;
}

export const Header: FC<HeaderProps> = ({
  isConnected,
  showApiKey,
  apiKey,
  currentVoice,
  timeRemaining,
  onVoiceChange,
  onConnect,
  onDisconnect,
  onResetApiKey,
  onLogout,
}) => {
  const [isSoundEnabled, setIsSoundEnabled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="content-top">
      <div className="content-title">
        <img src="/dataipa.png" alt="DataIPA Logo" className="logo" />
        <span>Realtime Assistant</span>
      </div>

      <button
        className="hamburger-menu"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-expanded={isMenuOpen}
      >
        <Menu size={24} />
      </button>

      <div className={`content-controls ${isMenuOpen ? 'open' : ''}`}>
        <BackgroundAudio src="Office-Room.mp3" enabled={isSoundEnabled} />
        <button
          className="sound-toggle-button"
          onClick={() => setIsSoundEnabled(!isSoundEnabled)}
          title={
            isSoundEnabled
              ? 'Désactiver le bruit de fond'
              : 'Activer le bruit de fond'
          }
        >
          {isSoundEnabled ? <Volume2 size={16} /> : <VolumeX size={16} />}
          <span>BRUIT DE FOND</span>
        </button>

        <VoiceDropdown
          value={currentVoice}
          onChange={onVoiceChange}
          disabled={isConnected}
        />

        {timeRemaining && isConnected && (
          <div
            className={`connection-status ${
              timeRemaining.startsWith('00') ? 'disconnected' : 'connected'
            }`}
          >
            {timeRemaining}
          </div>
        )}

        <button
          className={`custom-button ${
            isConnected ? 'button-regular' : 'button-action'
          }`}
          onClick={isConnected ? onDisconnect : onConnect}
        >
          {isConnected ? <X size={16} /> : <Zap size={16} />}
          <span>{isConnected ? 'Déconnecter' : 'Connecter'}</span>
        </button>

        {showApiKey && (
          <button
            className="custom-button button-regular"
            onClick={onResetApiKey}
          >
            <Edit size={16} />
            <span>API KEY: {apiKey.slice(0, 3)}...</span>
          </button>
        )}

        <div
          className={`connection-status ${
            isConnected ? 'connected' : 'disconnected'
          }`}
        >
          {isConnected ? 'Connecté' : 'Déconnecté'}
        </div>

        <button className="custom-button button-alert" onClick={onLogout}>
          <AlertCircle size={16} />
          <span>Déconnexion</span>
        </button>
      </div>
    </div>
  );
};
